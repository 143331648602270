import React, { useEffect, useRef, useState } from 'react';
import { BACKEND_URL } from '../../config';
import axios from 'axios';
const Application = () => {
    const [projectName,setProjectName] = useState("")
    const [twitter,setTwitter] = useState("")
    const [website,setWebsite] = useState("")
    const [telegramContact,setTelegramContact] = useState("")
    const [chainSelect,setChainSelect] = useState("")
    const [launchDate,setLaunchDate] = useState("")
    const [auditform,setAuditform] = useState("")
    const [error,setError] = useState("")
    const [succes,setSucces] = useState("")
    const [button,setButton] = useState(false)

    const isValidHttpUrl = (string) => {
        let url;
        try {
          url = new URL(string);
        } catch (_) {
          return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
    };

    const submit = () => {
        if (twitter && !isValidHttpUrl(twitter)) {
            setError("Enter valid twitter URL!")
            errorTimeout()
            return
        }
        if (website && !isValidHttpUrl(website)) {
            setError("Enter valid website URL!")
            errorTimeout()
            return
        }
        if(!projectName || !twitter || !website || !telegramContact || !chainSelect || !launchDate || !auditform){
            setError("All field is require!")
            errorTimeout()
            return
        }
        setButton(true)
        let sendObject = {
            projectName:projectName,
            twitter:twitter,
            website:website,
            telegramContact:telegramContact,
            chainSelect:chainSelect,
            launchDate:launchDate,
            form:auditform
        }
        axios.post(`${BACKEND_URL}/dataForm`,sendObject)
        .then((response) => {
            setButton(false)
            setSucces("Project form submitted successfully")
            setProjectName("")
            setTwitter("")
            setWebsite("")
            setTelegramContact("")
            setChainSelect("")
            setLaunchDate("")
            setAuditform("")
            errorTimeout()
        })
        .catch((err) => {});
    }

    const errorTimeout = () =>{
        setTimeout(()=>{
            setError("")
            setSucces("")
        },2000)
    }
    return (
        <>
            <div className='max-w-[50rem] px-[2rem] py-[1.5rem] max-[576px]:px-[1.5rem] max-[576px]:py-[1.5rem] bg-[hsla(0,0%,100%,.1)] three max-[576px]:mb-4'>
                <div className='[&_label]:text-sm [&_.flex]:mb-4 [&_label]:mb-1 [&_.text]:bg-[rgba(255,255,255,.2)] [&_.text]:rounded-sm [&_.text]:h-10 [&_.text]:px-4 [&_.text]:border [&_.text]:border-[rgba(255,255,255,.2)] [&_.text]:outline-none'>
                    <div className='grid grid-cols-3 max-[576px]:grid-cols-1 gap-4 max-[576px]:gap-0 four'>
                        <div className='flex flex-col'>
                            <label>Project Name<span className='text-[#f00]'>*</span></label>
                            <input type="text" name="" className='text' value={projectName} onChange={(e) => setProjectName(e.target.value)} placeholder='Project Name'/>
                        </div>
                        <div className='flex flex-col'>
                            <label>Twitter<span className='text-[#f00]'>*</span></label>
                            <input type="text" name="" className='text' value={twitter}  onChange={(e) => setTwitter(e.target.value)} placeholder='Twitter'/>
                        </div>
                        <div className='flex flex-col'>
                            <label>Website<span className='text-[#f00]'>*</span></label>
                            <input type="text" name="" className='text' value={website} onChange={(e) => setWebsite(e.target.value)} placeholder='Website'/>
                        </div>
                    </div>
                    <div className='grid grid-cols-2 max-[576px]:grid-cols-1 gap-4 max-[576px]:gap-0 four'>
                        <div className='flex flex-col'>
                            <label>Telegram Contact<span className='text-[#f00]'>*</span></label>
                            <input type="text" name="" className='text' value={telegramContact} onChange={(e) => setTelegramContact(e.target.value)} placeholder='Telegram Contact'/>
                        </div>
                        <div className='flex flex-col max-[576px]:col-span-12'>
                            <label className='flex-1'>Chain Select<span className='text-[#f00]'>*</span></label>
                            <input type="text" name="" className='text' value={chainSelect} onChange={(e) => setChainSelect(e.target.value)} placeholder='Chain Select'/>
                        </div>
                    </div>
                    <div className='grid grid-cols-2 max-[576px]:grid-cols-1 gap-4 max-[576px]:gap-0 five'>
                        <div className='flex flex-col max-[576px]:col-span-12'>
                            <label>Target Public Launch Date (IF pre-TGE - ie when are you launching? If your token is live, skip).</label>
                            <input type="date" name="" value={launchDate} onChange={(e) => setLaunchDate(e.target.value)} className='text w-full appearance-none'/>
                        </div>
                        <div className='flex flex-col five'>
                            <label>Link to Completed Audits - Form<span className='text-[#f00]'>*</span></label>
                            <textarea className='bg-[rgba(255,255,255,.2)] rounded-md px-4 py-2 resize-none outline-none' value={auditform} rows={2} onChange={(e) => setAuditform(e.target.value)} placeholder='Audits Form'></textarea>
                        </div>
                    </div>
                    {
                        error && <p className='text-center text-red-500 mb-0'>{error}</p>
                    }
                    {
                        succes && <p className='text-center text-green-500'>{succes}</p>
                    }
                    <div className='six max-[576px]:text-center'>
                        <button className='bg-gradient-to-r from-purple-500 to-pink-500 w-52 py-2.5 rounded-lg' type='button' disabled={button} onClick={() => submit()}>Submit</button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Application