import {BrowserRouter, Routes, Route } from 'react-router-dom';
import InnerOutlet from './Component/InnerOutlet';
import Landing from "./Pages/Landing";
import Privacy from "./Pages/Privacy";
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<InnerOutlet />}>
            <Route index element={<Landing />} />
            <Route path="/privacy" element={<Privacy />} />
          </Route>
        </Routes>
      </BrowserRouter>

    </>
  );
}

export default App;
