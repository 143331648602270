import React, { useState } from "react";

const Tab = ({ label, onClick, isActive }) => (
    <div
        className={`text-[1.25rem] max-[576px]:text-[.8rem] max-[576px]:text-center font-pjRegular cursor-pointer flex items-center leading-normal before:absolute before:left-0 before:h-[0.15rem] before:bg-white transition-all ${isActive ? " before:w-5 pl-8 !opacity-100 max-[576px]:pl-0 max-[576px]:before:hidden" : " before:w-0 !opacity-60"}`}
        onClick={onClick}
    >
        {label}
    </div>
);

export default Tab;