import React, { useState,useEffect } from 'react'
import poster1 from '../assets/videos/firstCircleBg_poster.jpg'
import video1 from '../assets/videos/firstCircleBg.mp4'
import { Link, NavLink } from 'react-router-dom';
const Footer = () => {
    useEffect(() => {
        const classExists = document.querySelector('.section.fourth.fp-completely') !== null;
        if (!classExists) return
        document.body.classList.add('saurabh')
    }, []);
    return (
        <>
        <div className='videos'>
            <div className='fluid-ball bigCircle'>
                <video autoPlay data-autoplay muted loop playsInline webkit-playsinline className='w-full h-full'>
                    <source src={video1} type="video/mp4"></source>
                </video>
            </div>
            <div className='fluid-ball smallVideo'>
                <video autoPlay data-autoplay muted loop playsInline webkit-playsinline className='w-full h-full'>
                    <source src={video1} type="video/mp4"></source>
                </video>
            </div>
            <div className='fluid-ball smallVideo3'>
                <video autoPlay data-autoplay muted loop playsInline webkit-playsinline className='w-full h-full'>
                    <source src={video1} type="video/mp4"></source>
                </video>
            </div>
        </div>
        <div className='footerlnks flex items-center absolute bottom-3 font-pjRegular left-0 right-0 justify-center gap-4 text-xs [&_a]:text-slate-300 max-[576px]:gap-2'>
            <NavLink to="/" className="link">Docs</NavLink>-
            <NavLink to="/privacy" className="link">Privacy Policy</NavLink>-
            <NavLink to="/" className="link">Partner Application</NavLink>
            {/* <NavLink to="/" className="link">Merch</NavLink> */}
        </div>
        </>
    )
}

export default Footer