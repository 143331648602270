import React, { useState } from "react";
import Tab from "./Faq";
import ArrowDown from '../../assets/icons/angle-down.svg'

const Tabs = ({ tabs }) => {
    const [activeTab, setActiveTab] = useState(1);
    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    const [openAccordion, setOpenAccordion] = useState(null);
    const handleClick = (index) => {
        if (index !== openAccordion) {
            setOpenAccordion(index);
        } else {
            setOpenAccordion(null);
        }
    };
 
    return (
        <div className='flex max-[576px]:block items-center max-[576px]:items-start'>
            <div className='w-[17.625rem] max-[576px]:w-full two'>
                <h3 className="max-[576px]:mt-4 text-[3.375rem] font-azonix gradientText inline-block leading-none max-[576px]:text-[2.375rem] one mb-10 max-[576px]:mb-0">FAQS</h3>
                <div className='flex flex-col max-[576px]:flex-row gap-6 max-[576px]:mt-4'>
                    {tabs.map((tab, index) => (
                        <Tab
                            key={index}
                            label={tab.label}
                            onClick={() =>
                                handleTabClick(index)
                            }
                            isActive={index === activeTab}
                        />
                    ))}
                </div>
            </div>
            <div className='three w-[6px] gardientV mx-28 max-[576px]:w-full max-[576px]:h-[4px] h-64 max-[576px]:mx-auto max-[576px]:mt-6 max-[576px]:mb-3 max-[576px]:hidden'></div>
            <div className={`items text-lg max-[576px]:text-sm font-pjRegular group [&_.text>div]:px-10 max-[576px]:[&_.text>div]:px-4 [&_.text]:text-[14px] [&_ul]:flex [&_ul]:my-2 [&_ul]:flex-col [&_ul]:gap-2 [&_.text]:font-pjlight [&_.text]:leading-normal [&_.text>div]:border-t-[1px] [&_.text>div]:border-slate-600 [&_.text]:transition-all [&_.text]:overflow-hidden [&_.text]:ease-in-out [&_.text]:duration-0 [&_.text]:max-h-0 activeTab${activeTab} max-[576px]:mt-4`}>
                <div className="item hidden flex-col gap-4 group-[.activeTab0]:flex w-[80vh] max-[576px]:w-full">
                    <div className={`one border border-slate-600 group ${openAccordion === 1 ? "open !bg-[rgba(255,255,255,.1)]" : ""}`}>
                        <div className='py-3 px-4 before:mr-5 before:w-1 before:h-1 before:min-w-1 before:rounded-full before:bg-white flex items-center cursor-pointer' onClick={() => handleClick(1)}>
                            <div className='mr-10'>What is MBD HybriDEX?</div>
                            <img src={ArrowDown} className='ml-auto h-2 transition-all inline-block group-[.open]:rotate-180'/>
                        </div>
                        <div className="text group-[.open]:max-h-screen">
                            <div className="py-5 max-[576px]:py-3">
                                MBD HybriDEX is an advanced non-custodial platform designed for secure, fast, and highly capital-efficient native cross-chain trading.
                            </div>
                        </div>
                    </div>
                    <div className={`two border border-slate-600 group ${openAccordion === 2 ? "open !bg-[rgba(255,255,255,.1)]" : ""}`}>
                        <div className='py-3 px-4 before:mr-5 before:w-1 before:h-1 before:min-w-1 before:rounded-full before:bg-white flex items-center cursor-pointer' onClick={() => handleClick(2)}>
                            <div className='mr-10'>How does MBD HybriDEX work?</div>
                            <img src={ArrowDown} className='ml-auto h-2 transition-all inline-block group-[.open]:rotate-180'/>
                        </div>
                        <div className="text group-[.open]:max-h-screen">
                            <div className="py-5 max-[576px]:py-3">
                                MBD HybriDEX operates through a self-organized peer-to-peer liquidity network instead of traditional liquidity pools. It leverages cross-chain communication between smart contracts, facilitated by advanced messaging infrastructure, to enable secure and efficient transfers.
                            </div>
                        </div>
                    </div>
                    <div className={`three border border-slate-600 group ${openAccordion === 3 ? "open !bg-[rgba(255,255,255,.1)]" : ""}`}>
                        <div className='py-3 px-4 before:mr-5 before:w-1 before:h-1 before:min-w-1 before:rounded-full before:bg-white flex items-center cursor-pointer' onClick={() => handleClick(3)}>
                            <div className='mr-10'>What are some of the advantages of Using MBD HybriDEX</div>
                            <img src={ArrowDown} className='ml-auto h-2 transition-all inline-block group-[.open]:rotate-180'/>
                        </div>
                        <div className="text group-[.open]:max-h-screen">
                            <div className="py-5 max-[576px]:py-3">
                                MBD HybriDEX addresses several critical challenges that traditional cross-chain platforms face:
                                <ul className='mt-8 opacity-[.85] text-[.875rem] font-pjlight flex flex-col gap-y-4 [&_li]:relative [&_li]:before:absolute [&_li]:before:-left-4 [&_li]:before:top-[2px] [&_li]:before:mt-2 [&_li]:before:mr-4 [&_li]:before:min-w-2 [&_li]:before:h-[2px] [&_li]:before:bg-white [&_li]:before:rounded-full'>
                                    <li><span className="font-pjBold">Cost Efficiency:</span> Trade with minimal fees, as low as 8bps, without additional costs from AMMs or losses due to MEV and slippage.</li>
                                    <li><span className="font-pjBold">Enhanced Liquidity:</span> Access liquidity from the entire market rather than being restricted to locked pools on the destination chain.</li>
                                    <li><span className="font-pjBold">Slippage and MEV Protection:</span> Always receive the exact amount displayed, avoiding unpredictable outcomes.</li>
                                    <li><span className="font-pjBold">Reliable Transactions:</span> Avoid transaction reverts common with classical bridges, ensuring your transfers finalize without issues.</li>
                                    <li><span className="font-pjBold">Fast Settlement:</span> Enjoy transactions settled within seconds or minutes, unlike other platforms with lengthy finality times.</li>
                                    <li><span className="font-pjBold">Native Asset Transfers:</span> Conduct trades directly with native tokens, eliminating risks associated with bad liquidity and slippage from wrapped assets.</li>
                                </ul>
                                By using MBD HybriDEX, traders benefit from a more efficient, reliable, and secure cross-chain trading experience.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item hidden flex-col gap-4 group-[.activeTab1]:flex w-[80vh] max-[576px]:w-full">
                    <div className={`one border border-slate-600 group ${openAccordion === 4 ? "open !bg-[rgba(255,255,255,.1)]" : ""}`}>
                        <div className='py-3 px-4 before:mr-5 before:w-1 before:h-1 before:min-w-1 before:rounded-full before:bg-white flex items-center cursor-pointer' onClick={() => handleClick(4)}>
                            <div className='mr-10'>What assets and chains are supported?</div>
                            <img src={ArrowDown} className='ml-auto h-2 transition-all inline-block group-[.open]:rotate-180'/>
                        </div>
                        <div className="text group-[.open]:max-h-screen">
                            <div className="py-5 max-[576px]:py-3">
                                MBD HybriDEX facilitates trading of various assets across Ethereum, Arbitrum, Polygon, BNB Chain, and Avalanche. The platform plans to expand support to additional blockchains, both EVM and non-EVM, in the near future.
                            </div>
                        </div>
                    </div>
                    <div className={`two border border-slate-600 group ${openAccordion === 5 ? "open !bg-[rgba(255,255,255,.1)]" : ""}`}>
                        <div className='py-3 px-4 before:mr-5 before:w-1 before:h-1 before:min-w-1 before:rounded-full before:bg-white flex items-center cursor-pointer' onClick={() => handleClick(5)}>
                            <div className='mr-10'>How do I create a market order or limit order on MBD HybriDEX?</div>
                            <img src={ArrowDown} className='ml-auto h-2 transition-all inline-block group-[.open]:rotate-180'/>
                        </div>
                        <div className="text group-[.open]:max-h-screen">
                            <div className="py-5 max-[576px]:py-3">
                                For market orders, select the token and blockchain you wish to trade on, and your order will execute at the current market price. To create a limit order, you can specify the desired buy price or total amount. Market orders are executed immediately, while limit orders may take longer to fulfill as they wait for the specified price.
                            </div>
                        </div>
                    </div>
                    <div className={`three border border-slate-600 group ${openAccordion === 6 ? "open !bg-[rgba(255,255,255,.1)]" : ""}`}>
                        <div className='py-3 px-4 before:mr-5 before:w-1 before:h-1 before:min-w-1 before:rounded-full before:bg-white flex items-center cursor-pointer' onClick={() => handleClick(6)}>
                            <div className='mr-10'>Market order vs. limit order?</div>
                            <img src={ArrowDown} className='ml-auto h-2 transition-all inline-block group-[.open]:rotate-180'/>
                        </div>
                        <div className="text group-[.open]:max-h-screen">
                            <div className="py-5 max-[576px]:py-3">
                                Market orders execute immediately. Limit orders allow price control but may take longer to fulfill.
                            </div>
                        </div>
                    </div>
                    <div className={`four border border-slate-600 group ${openAccordion === 7 ? "open !bg-[rgba(255,255,255,.1)]" : ""}`}>
                        <div className='py-3 px-4 before:mr-5 before:w-1 before:h-1 before:min-w-1 before:rounded-full before:bg-white flex items-center cursor-pointer' onClick={() => handleClick(7)}>
                            <div className='mr-10'>What are the fees associated with trading on MBD HybriDEX?</div>
                            <img src={ArrowDown} className='ml-auto h-2 transition-all inline-block group-[.open]:rotate-180'/>
                        </div>
                        <div className="text group-[.open]:max-h-screen">
                            <div className="py-5 max-[576px]:py-3">
                                fee of 8bps, which is divided between the platform and the taker fulfilling the order. Limit orders may incur higher fees for the taker. The fee structure is designed to be competitive and cost-effective.
                            </div>
                        </div>
                    </div>
                    <div className={`five border border-slate-600 group ${openAccordion === 8 ? "open !bg-[rgba(255,255,255,.1)]" : ""}`}>
                        <div className='py-3 px-4 before:mr-5 before:w-1 before:h-1 before:min-w-1 before:rounded-full before:bg-white flex items-center cursor-pointer' onClick={() => handleClick(8)}>
                            <div className='mr-10'>How does MBD HybriDEX handle order fulfillment and settlement?</div>
                            <img src={ArrowDown} className='ml-auto h-2 transition-all inline-block group-[.open]:rotate-180'/>
                        </div>
                        <div className="text group-[.open]:max-h-screen">
                            <div className="py-5 max-[576px]:py-3">
                                placed, the specified amount of tokens is temporarily locked by the smart contract on the source chain. Takers with sufficient liquidity on the destination chain can fulfill the order by providing the required tokens. After fulfillment, a cross-chain message is sent to release the funds on the source chain, completing the transaction.
                            </div>
                        </div>
                    </div>
                    <div className={`six border border-slate-600 group ${openAccordion === 9 ? "open !bg-[rgba(255,255,255,.1)]" : ""}`}>
                        <div className='py-3 px-4 before:mr-5 before:w-1 before:h-1 before:min-w-1 before:rounded-full before:bg-white flex items-center cursor-pointer' onClick={() => handleClick(9)}>
                            <div className='mr-10'>Why choose MBD HybriDEX over traditional cross-chain platforms?</div>
                            <img src={ArrowDown} className='ml-auto h-2 transition-all inline-block group-[.open]:rotate-180'/>
                        </div>
                        <div className="text group-[.open]:max-h-screen">
                            <div className="py-5 max-[576px]:py-3">
                                several benefits over traditional platforms, including lower fees, greater liquidity, protection from slippage and MEV, no reverted transactions, quicker settlement times, and native asset transfers without the risks associated with wrapped assets. These features make MBD HybriDEX a highly efficient and secure option for cross-chain trading.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item hidden flex-col gap-4 group-[.activeTab2]:flex w-[80vh] max-[576px]:w-full">
                    <div className={`one border border-slate-600 group ${openAccordion === 10 ? "open !bg-[rgba(255,255,255,.1)]" : ""}`}>
                        <div className='py-3 px-4 before:mr-5 before:w-1 before:h-1 before:min-w-1 before:rounded-full before:bg-white flex items-center cursor-pointer' onClick={() => handleClick(10)}>
                            <div className='mr-10'>Is MBD HybriDEX secure?</div>
                            <img src={ArrowDown} className='ml-auto h-2 transition-all inline-block group-[.open]:rotate-180'/>
                        </div>
                        <div className="text group-[.open]:max-h-screen">
                            <div className="py-5 max-[576px]:p-3">
                                Yes, MBD HybriDEX is fully non-custodial and designed with robust security measures. However, as with all DeFi protocols, usage is at your own risk. Please review the Terms and Conditions for more details.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
 
export default Tabs;